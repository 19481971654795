@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (min-width: 1440px) {
  .container{
    padding-left: 70px !important;
    padding-right: 70px !important;
    max-width: 1280px !important;
  }
}

@media (min-width: 1440px) {
  .main-container{
    max-width: 1440px !important;
  }
}

body {
  font-family: 'Montserrat', sans-serif;
  min-width: calc(100vw - 15px);
  min-height: 100vh;
  -webkit-backface-visibility: hidden;
}

/* -- ANIMATIONS --*/
.example-appear {
  opacity: 0.01;
}

.exp-logo {
  cursor: pointer;
}

.example-appear.example-appear-active {
  opacity: 1;
  transition: opacity .2s ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}
/* -- ANIMATIONS --*/

/* -- MENU --*/
.menu-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background: black;
  z-index: 3;
  overflow: auto;
}

.menu-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  margin: auto;
  margin-top: 75px;
  text-align: left;
  padding-left: 15px;
}

.menu-logo {
  margin-left: 20px !important;
}

.menu-title {
  font-family: 'Oswald', sans-serif;
  font-size: 50px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.08;
  letter-spacing: normal;
  color: #c7a56b;
  margin-bottom: 20px;
  padding-left: 20px;
  cursor: pointer;
  transition: all 0.3s;
}

.menu-title a{
  font-family: 'Oswald', sans-serif;
  font-size: 50px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.08;
  letter-spacing: normal;
  color: #c7a56b;
  margin-bottom: 20px;
  cursor: pointer;
  transition: all 0.3s;
}

.menu-title a:hover {
  color: white;
  text-decoration: none;
}

.menu-title:hover {
  color: white;
  text-decoration: none;
}

.menu-subtitle {
  font-family: 'Oswald', sans-serif;
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 2px;
  color: #ffffff;
  margin-top: 20px;
  padding-left: 20px;
}

.menu-subtitle-first {
  margin-top: 100px;
}

.menu-close {
  width: 120px;
  cursor: pointer;
  color: white;
  text-align: right;
}

.menu-x {
  margin-right: 5px;
}

.menu-social-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 35px;
  text-align: right;
}

.menu-social-content{
  padding-right: 70px;
}

.menu-social-content:first-of-type {
  margin-bottom: 45px;
}

.menu-social-text-small {
  font-family: 'Oswald', sans-serif;
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 2px;
  color: #ffffff;
}

.menu-social-text-small a{
  font-family: 'Oswald', sans-serif;
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 2px;
  color: #ffffff;
}

.menu-container-left {
  padding-left: 50px !important;
}

.menu-social-text{
  opacity: 0.8;
  font-family: 'Oswald', sans-serif;
  font-size: 32px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.3s;
}

.menu-social-text:hover {
  color: #EACA94;
  text-decoration: none;
}


.menu-social-text a{
  opacity: 0.8;
  font-family: 'Oswald', sans-serif;
  font-size: 32px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.3s;
}

.menu-social-text a:hover {
  color: #EACA94;
  text-decoration: none;
}

/* -- MENU --*/

.app-header {
  height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.brand {
  color: #c7a56b;
}

.subtitle {
  margin-top: 80px;
  margin-bottom: 42px;
  font-family: 'Oswald', sans-serif;
  font-size: 30px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.4;
  letter-spacing: normal;
  text-align: center;
  color: #c7a56b;
}

.intro {
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.menu-link {
  margin: auto;
  display: flex;
  flex-direction: row;
  z-index: 10;
  cursor: pointer;
  justify-content: center;
}

.menu-link-icons {
  height: 22px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.menu-link-icon {
  margin-top: 1px;
  width: 24px;
  height: 2px;
  background-color: #ffffff;
}

.menu-link-icon:last-of-type {
  width: 12px;
  margin-left: 12px;
  margin-top: 3px;
}

.menu-link-text {
  position: relative;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: right;
  color: #ffffff;
}

.menu-link-text::after {
  position: absolute;
  content: "";
  margin-top: 20px;
  width: 100%;
  height: 3px;
  top: 100%;
  left: 0;
  background: #ed3d37;
  transition: transform 0.1s;
  transform: scaleY(0);
  transform-origin: bottom;
}

.box-container {
  margin-top: 115px;
}

.box {
  width: 31.66%;
  justify-content: center;
  text-align: center;
}

.box:first-of-type {
  margin-left: 2.5%;
}

.box:last-of-type {
  margin-right: 2.5%;
}

.byc-text {
  color: white;
  padding-left: 10px;
  padding-right: 20px;
}

.box-title {
  font-family: 'Oswald', sans-serif;
  font-size: 40px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.8;
  letter-spacing: normal;
  color: #c7a56b;
}

.box-content {
  margin: auto;
  max-width: 340px;
  padding-left: 10px;
  padding-right: 10px;
}

.box-text {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #f1f1f1;
}

.box-link {
  margin-top: 5px;
  color: #c7a56b;
}

.box-link:hover {
  color: #fcc566;
}

.box-logo {
  object-fit: contain;
  margin-top: 30px;
  margin-bottom: 25px;
}

.program-item {
  height: 50px;
  display: flex;
  margin-top: 20px;
  margin-bottom: 70px;
}

.program-item-text {
  font-family: 'Oswald', sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin: auto;
}

.program-item-text-alt {
  font-family: 'Oswald', sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin: auto;
}

.program-item-content {
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: center;
  color: #a7a9ac;
}

.program-item-icon {
  width: 50px;
  height: 50px;
  margin-top: -10px;
  margin-right: 15px;
  float: left;
  display: block;
  object-fit: contain;
}

.nopadding {
  padding: 0 !important;
}

.nopaddingleft {
  padding-right: 0 !important;
}

.nopaddingright {
  padding-left: 0 !important;
}

.celebration {
  min-height: 570px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.izquierda {
  background-image: linear-gradient(to left, #d9bd88, #b18950);
}

.cardtext {
  text-align: left;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex: 1 1;
}

.cardtext-subtitle {
  margin-top: 45px;
  font-family: 'Oswald', sans-serif;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.73;
  letter-spacing: 0.83px;
  color: #ffffff;
  flex: 0 1;
}

.cardtext-title {
  font-family: 'Oswald', sans-serif;
  font-size: 50px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: 0.89px;
  color: #ffffff;
}

.cardtext-content {
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #f1f1f1;
}

.cardtext-button {
  width: 208px;
  height: 50px;
  display: flex;
  align-items: center;
  background-color: #000000;
  font-family: 'Oswald', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 5px;
  color: #ffffff;
  transition: all 0.3s;
  cursor: pointer;
}

.cardtext-button:hover {
  color: black;
  background-color: white;
}

.cardtext-button:hover .cardtext-arrow {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

.cardtext-arrow {
  vertical-align: unset;
  margin-left: 8px;
  transition: all 0.3s;
}

.nuestras {
  margin-top: 140px;
  font-family: 'Oswald', sans-serif;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 7.5px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 0;
}

.experiencias {
  margin-top: 0;
  font-family: 'Oswald', sans-serif;
  font-size: 50px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: #c7a56b;
}

.contentbox {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 610px;
  height: 480px;
  box-shadow: 0 40px 50px 0 rgba(0, 0, 0, 0.1);
  background-color: #000000;
  margin-top: 40px;
  padding: 60px;
  z-index: 1;
  position: relative;
}

.contentbox-left {
  margin-left: 230px;
}

.contentbox-right {
  margin-left: -230px;
}

.contentbox-subtitle {
  font-family: 'Oswald', sans-serif;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 7.5px;
  color: #ffffff;
}

.contentbox-title {
  font-family: 'Oswald', sans-serif;
  font-size: 60px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #c7a56b;
}

.contentbox-text {
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #a7a9ac;
}

.contentbox-button {
  width: 208px;
  height: 50px;
  display: flex;
  align-items: center;
  background-color: #000000;
  font-family: 'Oswald', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 3.5px;
  color: #ffffff;
  transition: all 0.3s;
  cursor: pointer;
  border: solid 1px #c7a56b;
}

.contentbox-button:hover {
  color: black;
  background-color: #c7a56b;
}

.contentbox-button:hover .contentbox-arrow {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

.contentbox-arrow {
  margin-right: 20px;
  vertical-align: unset;
  transition: all 0.3s;
}

.social {
  margin-top: 120px;
  height: 236px;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.social-title {
  font-family: 'Oswald', sans-serif;
  font-size: 32px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 8px;
  text-align: center;
  color: #2f3141;
}

.social-item {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 50px;
  color: #c7a56b;
}

.social-item:hover {
  text-decoration: none;
  color: #c7a56b;
}

.margin-row {
  margin-top: 52px;
  margin-bottom: 52px;
}

.center3 {
  text-align: center;
  justify-content: center;
}

.hr {
  background-color: rgba(255, 255, 255, 0.2);
  height: 1px;
  margin-top: 40px;
  margin-left: 40px;
  margin-right: 40px;
}

.vertical-center {
  align-items: center;
}

.bases-text {
  color: white;
  padding: 20px;
  text-align: left;
}

.modal-danger .modal-content {
  border-color: #f86c6b;
}

.modal-danger .modal-header {
  color: #fff;
  background-color: #f86c6b;
}

.modal-success .modal-content {
  border-color: #4dbd74;
}

.modal-success .modal-header {
  color: #fff;
  background-color: #4dbd74;
}

.link {
  text-decoration: none;
  color: white;
}

.link:hover {
  text-decoration: none;
}

a:hover {
  text-decoration: none !important;
}

@media (max-width: 768px) {
  .app-header {
    margin-top: 10px;
    height: auto;
    margin-bottom: 40px;
  }

  .bases-text {
    padding: 0;
  }

  .container-fluid {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .container-fluid .row {
    margin-left: 0;
    margin-right: 0;
  }

  .menu-container-left {
    padding-left: 0 !important;
  }

  .menu-title-first-m {
    margin-top: 50px !important;
  }

  .menu-logo-m {
    margin-top: -5px !important;
  }

  .menu-wrapper {
    margin-top: 27px;
    width: 100%;
    padding-left: 0;
  }

  .nopadding-m {
    padding: 0 !important;
  }

  .menu-social-container {
    text-align: left;
    padding-left: 0;
  }

  .menu-subtitle {
    margin-top: 0;
    padding-left: 10px;
    font-size: 16px;
  }

  .menu-title {
    font-size: 32px;
    padding-left: 10px;
    margin-top: 15px;
  }

  .menu-social-text {
    padding-left: 10px;
    font-size: 24px;
  }

  .menu-social-text a{
    padding-left: 10px;
    font-size: 24px;
  }

  .menu-social-text-small a{
    padding-left: 10px;
  }

  .menu-social-text-small {
    padding-left: 10px;
  }

  .menu-close {
    width: 100%;
    text-align: right;
    margin-top: 0;
    padding-right: 25px;
  }

  .subtitle-alt {
    margin-top: -10px;
  }

  .intro {
    font-size: 20px;
  }

  .program-item {
    margin-bottom: 30px;
  }

  .box {
    width: 100%;
    margin-top: 20px;
    margin-left: 2.5%;
  }

  .box-container {
    margin-top: 20px;
  }

  .cardtext {
    position: relative;
  }

  .izquierda::before {
    content: "";
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    -webkit-filter: brightness(0.8);
            filter: brightness(0.8);
  }

  .cardtext-button {
    background-color: #c7a56b;
  }

  .izquierda {
    position: relative;
  }

  .derecha {
    display: none;
  }

  .row-inverted-m {
    flex-direction: column-reverse;
  }

  .contentbox {
    margin: auto !important;
    width: auto !important;
    height: auto !important;
  }

  .cardtext-title {
    font-size: 42px;
  }

  .cardtext-subtitle {
    line-height: 1;
  }

  .social-and {
    display: none;
  }

  .program-item-text {
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
  }

  .nuestras {
    margin-top: 45px;
  }

  .experiencias {
    margin-bottom: 30px;
  }

  .margin-row {
    margin-top: auto;
    margin-bottom: auto;
  }

  .quiz {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 100%;
  }

  .quiz-container {
    max-height: 280px;
    box-shadow: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    background-image: none;
  }

  .quiz-introtext {
    font-size: 20px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }

  .quiz-button-left {
    margin-right: auto;
  }

  .quiz-button-right {
    margin-left: auto;
  }

  .quiz-topbar {
    padding: 20px;
  }

  .quiz-wrapper {
    padding-left: 0;
    width: 100%;
  }

  .quiz-row {
    display: none !important;
  }

  .quiz-row-mobile {
    display: block !important;
    flex-direction: column;
    align-items: center;
  }

  .quiz-button {
    background: transparent;
    height: auto;
    width: auto;
  }

  .quiz-button:hover {
    color: #c7a56b;
  }

  .quiz-end-text {
    font-size: 24px;
  }

  .quiz-end-buttons-container {
    margin-top: 75px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 120px;
  }

  .program-item-alt {
    margin-bottom: 65px;
  }

  .menu-social-content{
    margin-top: 20px;
  }

  .menu-social-content:first-of-type {
    margin-bottom: 0;
  }

  .menu-subtitle {
    display: none;
  }

  .menu-social-text-small {
    display: none;
  }

  .menu-social-text-small a{
    display: none;
  }

  .menu-title-first {
    margin-top: 50px
  }
}

@media (max-width: 445px) {
  .quiz-introtext {
    display: none;
  }

  .program-item-text {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
  }

  .menu-social-text {
    margin-left: 0;
    padding-left: 0;
  }

  .menu-social-text a{
    margin-left: 0;
    padding-left: 0;
  }
}

@media (max-width: 320px) {
  .program-item-text {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
}

.app {
    font-family: "Montserrat", sans-serif;
}

.menu {
    position: absolute;
    width: 100vw;
    z-index: 3;
    margin-left: -15px;
    margin-right: -15px;
}

.color-red {
    color: #ed3d37;
}

.p-35 {
    padding: 35px;
}

.swiper-container {
    height: 390px;
}

.swiper-pagination-bullet {
    opacity: 1 !important;
    border: white solid 1px !important;
    background-color: transparent !important;
}

.swiper-pagination-bullet-active {
    background-color: white !important;
}

.scrolling {
    position: fixed;
    transition: all 0.7s;
}

.black {
    background: black;
}

.small {
    height: 100px;
}

.section_wide {
    display: block;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 0;
}

.section_first {
    background-image: url(/static/media/bg.43a99b5b.jpg);
    height: 720px;
    z-index: 1;
}

.section_intro {
    padding-top: 250px;
    padding-bottom: 250px;
    color: #FFFFFF;
}

.section_intro h1 {
    font-size: 56px;
    line-height: 68px;
    font-weight: 700;
}

.section_intro h4 {
    font-size: 18px;
    font-weight: 600;
}

.section_intro .outro {
    margin-top: -160px;
    margin-bottom: 240px;
}

.section_intro .know_us {
    margin-left: 15px;
    font-size: 16px;
    font-weight: 600;
    color: #ed4334;
}

.section_overlay {
    z-index: 2;
    position: relative;
    margin-top: -75px;
    max-width: 1082px;
    height: 160px;
    background: white;
    border-radius: 2px;
    box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.12);
}

.section_overlay h1 {
    margin-top: 33px;
    font-size: 32px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.7px;
    text-align: center;
    color: #000000;
}

.section_padding {
    position: relative;
    padding-top: 125px;
    padding-bottom: 125px;
}

.section_padding h4 {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    color: #ed3d37;
}

.section_padding h1 {
    min-height: 58px;
    font-size: 32px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #000000;
}

.section_gray {
    background: #f7f7f7;
}

.section_black {
    background: #000000;
}

.section_red {
    background: #ed3d37;
}

.section_red a, p {
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.34px;
    color: #ffffff;
}

.section_red a:hover {
    color: white;
    text-decoration: underline !important;
}

.section_red h1 {
    font-size: 42px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.05;
    letter-spacing: -0.91px;
    color: #ffffff;
}

.section_black h1 {
    font-size: 36px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.89;
    letter-spacing: -0.78px;
    text-align: center;
    color: #ffffff;
}

.section_gray h4 {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    color: #ed3d37;
}

.section_gray h1 {
    font-size: 32px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.13;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
}

.section_gray .plus-sign {
    font-weight: 600;
    color: #ed3d37;
}

.rojo {
    margin-top: -100px;
}

.rojo-lessmargin {
    margin-top: -20px;
}

.rojo2 {
    position: absolute;
    opacity: 0;
    margin-top: 0;
    margin-left: -50px;
}

.rojo2-right {
    margin-left: 0;
    right: -20px;
}

.content-right {
    display: flex;
    margin-left: auto;
}

.content-overlap-right {
    display: flex;
    margin-right: -90px;
}

.content-left {
    display: flex;
    margin-right: auto;
}

.content-overlap-left {
    display: flex;
    margin-left: -60px;
}

.content-overlap-left-90 {
    display: flex;
    margin-left: -90px;
}

.vertical-center {
    display: flex;
}

.content-card {
    padding: 30px;
    height: 300px;
    border-radius: 2px;
    box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
    flex-direction: column;
    width: 100%;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: stretch;
}

.content-card ul {
    list-style-type: none
}

.content-card li {
    font-size: 22px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.92;
    letter-spacing: normal;
    color: #000000;
}

.content-red {
    color: #ed3d37;
}

.content-span {
    width: 21px;
    height: 48px;
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #ed3d37;
    position: absolute;
    margin-left: -35px;
}

.content-card-dwarf {
    height: 220px;
}

.content-card-dwarf li {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.2;
    letter-spacing: normal;
    color: #000000;
}

.content-card h2 {
    font-size: 26px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.5px;
    color: #ed3d37;
}

.content-card-limited {
    max-width: 625px;
}

.content {
    padding-top: 25px;
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #000000;
    max-width: 620px;
}

.plussign {
    font-size: 48px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #ed3d37;
    position: absolute;
}

.spaced {
    margin-left: 38px;
}

.diff {
    color: #ed3d37;
}

.padding-top {
    padding-top: 100px;
}

.p-40 {
    padding: 40px;
}

.z1 {
    z-index: 1;
}

.services-text {
    font-size: 24px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #000000;
}

.more-services-text {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: normal;
    color: #000000;
}

.we-text {
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #000000;
}

.logica {
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #000000;
}

.albert {
    margin-top: 54px;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.13;
    letter-spacing: normal;
    color: #ed4334;
}

.footer {
    height: 200px
}

.footer p {
    width: 100%;
    margin-top: 20px;
    opacity: 0.5;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #ffffff;
}

.links {
    align-items: center;
    margin-left: auto !important;
    margin-top: 10px;
}

.footer-logo {
    text-align: left;
}

.active {
    opacity: 1 !important;
    z-index: 3;
}

.icon {
    width: 23px;
    height: 21px;
}

.fade {
    display: none;
}

.caret {
    font-size: 18px;
    margin-right: 7px;
}

/* Style the buttons that are used to open and close the ºrdion panel */
.accordion {
    margin-top: 10px;
    width: 380px;
    background-color: transparent;
    cursor: pointer;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ed3d37;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

/* Style the accordion panel. Note: hidden by default */
.panel {
    padding: 0 18px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in;
}

.bio {
    padding: 0 40px 40px;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #000000;
}

.padding-5 {
    padding: 3rem;
}

@media (max-width: 1440px) {
    .bio {
        padding: 0;
    }
}

@media (min-width: 768px) {
    .hide-md {
        display: none !important;
    }

    .rojo2-right {
        right: -70px;
    }

    .menu-link-text.active::after {
        transform: scaleY(1);
    }

    .menu-link-text:hover::after {
        transform: scaleY(1);
        transform-origin: bottom;
    }
}

@media (max-width: 768px) {
    .padding-5 {
        padding: 1rem;
    }

    .rojo-left {
        width: 69px;
        position: absolute;
        left: 20px;
        margin-top: -36px;
        z-index: 1;
    }

    .accordion {
        width: 100%;
    }

    .section_first {
        text-align: center;
        z-index: 1;
    }

    .section_intro {
        padding-top: 100px;
        padding-bottom: 50px;
    }

    .section_intro h4 {
        margin-top: 100px;
    }

    .section_intro h1 {
        padding-left: 15px;
        padding-right: 15px;
        font-size: 36px;
        line-height: 46px;
    }

    .section_intro .outro {
        margin-top: 20px;
    }

    .more-services-text {
        padding-top: 22px;
    }

    .section_overlay {
        z-index: 1;
        margin-top: 0;
        max-width: 1082px;
        height: auto;
        background: white;
        border-radius: 0;
        box-shadow: none;
        padding-bottom: 20px;
    }

    .section_overlay h1 {
        font-size: 26px;
        line-height: 36px;
    }

    .section_padding {
        text-align: center;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .section_padding h1 {
        font-size: 26px;
        line-height: 36px;
    }

    .mp {
        color: white !important;
    }

    .section_padding h4 {
        font-size: 26px;
    }

    .plussign {
        font-size: 40px;
        margin-left: 4px;
    }

    .we-text {
        font-size: 14px;
    }

    .section_red a, p {
        font-size: 22px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.34px;
        color: #000000;
    }

    .p-5 {
        padding: 2rem !important;
    }

    .content_strategy {
        font-size: 16px;
        padding-bottom: 50px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .rojo {
        width: 109px;
        height: 134px;
        position: absolute;
        right: 20px;
        margin-top: -20px;
        z-index: 1;
    }

    .hide-sm {
        display: none !important;
    }

    .section_gray h1 {
        font-size: 26px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.23;
        letter-spacing: normal;
        text-align: center;
        color: #000000;
    }

    .section_gray p {
        margin-top: 20px;
    }

    .p-owner {
        padding-left: 40px;
        padding-right: 40px;
    }

    .content-card {
        border-radius: 0;
        height: auto;
        box-shadow: none;
        text-align: left;
        padding-left: 0;
        padding-top: 15px;
        padding-right: 0;
    }

    .content-card li {
        font-size: 18px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.52;
        letter-spacing: normal;
        color: #000000;
    }

    .content-span {
        width: 21px;
        height: 48px;
        font-size: 35px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.82;
        letter-spacing: normal;
        color: #ed3d37;
        position: absolute;
        margin-left: -35px;
    }

    .content-card h2{
        font-size: 20px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.82;
        letter-spacing: -0.32px;
        color: #ed3d37;
    }

    .content-card p {
        font-size: 18px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.36;
        letter-spacing: normal;
        color: #000000;
    }

    .content-card-dwarf {
        margin-top: 20px;
        margin-bottom: 20px;
        text-align: center;
        padding-left: 25px;
        padding-right: 25px;
    }

    .content-card-dwarf li {
        text-align: left;
    }

    .rojo-lessmargin {
        margin-bottom: 20px;
        width: 160px;
        height: 195px
    }

    .logica {
        padding-left: 15px;
        padding-right: 15px;
        font-size: 26px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.54;
        letter-spacing: normal;
        text-align: center;
        color: #000000;
    }

    .albert {
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        color: #ed3d37;
    }

    .footer-logo {
        text-align: center;
    }

    .menu {
        margin-left: 0;
        margin-right: 0;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        transition: all 0.7s;
        -webkit-transition: all 0.7s;
        -moz-transition: all 0.7s;
        -o-transition: all 0.7s;
    }

    .menu-link {
        margin-left: auto;
        margin-right: 20px;
    }

    .section_red {
        height: 100vh;
    }

    .menu-link-text {
        position: relative;
        font-size: 18px;
        line-height: 30px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: .2px;
        text-align: right;
        color: #fff;
    }

    .menu-link-text::after {
        position: absolute;
        content: "";
        margin-top: 0;
        width: 100%;
        height: 3px;
        top: 100%;
        left: 0;
        background: #ed3d37;
        transition: transform 0.1s;
        transform: scaleY(0);
        transform-origin: bottom;
    }

    .black-menu {
        background: black;
    }

    .mob-logo {
        height: 32px;
        cursor: pointer;
    }

    .exp-logo {
        height: 70px;
    }

    .hamburger {
        height: 17px;
    }

    .small {
        height: 35px;
        margin-top: 15px;
        margin-bottom: 32px;
    }

    .ext-hamburger {
        display: none;
        z-index: 20;
        top: 92px;
        left: 0;
        right: 0;
        position: fixed;
    }

    .swiper-container {
        height: 490px;
    }
}
