.app {
    font-family: "Montserrat", sans-serif;
}

.menu {
    position: absolute;
    width: 100vw;
    z-index: 3;
    margin-left: -15px;
    margin-right: -15px;
}

.color-red {
    color: #ed3d37;
}

.p-35 {
    padding: 35px;
}

.swiper-container {
    height: 390px;
}

.swiper-pagination-bullet {
    opacity: 1 !important;
    border: white solid 1px !important;
    background-color: transparent !important;
}

.swiper-pagination-bullet-active {
    background-color: white !important;
}

.scrolling {
    position: fixed;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
}

.black {
    background: black;
}

.small {
    height: 100px;
}

.section_wide {
    display: block;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 0;
}

.section_first {
    background-image: url(images/bg.jpg);
    height: 720px;
    z-index: 1;
}

.section_intro {
    padding-top: 250px;
    padding-bottom: 250px;
    color: #FFFFFF;
}

.section_intro h1 {
    font-size: 56px;
    line-height: 68px;
    font-weight: 700;
}

.section_intro h4 {
    font-size: 18px;
    font-weight: 600;
}

.section_intro .outro {
    margin-top: -160px;
    margin-bottom: 240px;
}

.section_intro .know_us {
    margin-left: 15px;
    font-size: 16px;
    font-weight: 600;
    color: #ed4334;
}

.section_overlay {
    z-index: 2;
    position: relative;
    margin-top: -75px;
    max-width: 1082px;
    height: 160px;
    background: white;
    border-radius: 2px;
    box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.12);
}

.section_overlay h1 {
    margin-top: 33px;
    font-size: 32px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.7px;
    text-align: center;
    color: #000000;
}

.section_padding {
    position: relative;
    padding-top: 125px;
    padding-bottom: 125px;
}

.section_padding h4 {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    color: #ed3d37;
}

.section_padding h1 {
    min-height: 58px;
    font-size: 32px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #000000;
}

.section_gray {
    background: #f7f7f7;
}

.section_black {
    background: #000000;
}

.section_red {
    background: #ed3d37;
}

.section_red a, p {
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.34px;
    color: #ffffff;
}

.section_red a:hover {
    color: white;
    text-decoration: underline !important;
}

.section_red h1 {
    font-size: 42px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.05;
    letter-spacing: -0.91px;
    color: #ffffff;
}

.section_black h1 {
    font-size: 36px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.89;
    letter-spacing: -0.78px;
    text-align: center;
    color: #ffffff;
}

.section_gray h4 {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    color: #ed3d37;
}

.section_gray h1 {
    font-size: 32px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.13;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
}

.section_gray .plus-sign {
    font-weight: 600;
    color: #ed3d37;
}

.rojo {
    margin-top: -100px;
}

.rojo-lessmargin {
    margin-top: -20px;
}

.rojo2 {
    position: absolute;
    opacity: 0;
    margin-top: 0;
    margin-left: -50px;
}

.rojo2-right {
    margin-left: 0;
    right: -20px;
}

.content-right {
    display: flex;
    margin-left: auto;
}

.content-overlap-right {
    display: flex;
    margin-right: -90px;
}

.content-left {
    display: flex;
    margin-right: auto;
}

.content-overlap-left {
    display: flex;
    margin-left: -60px;
}

.content-overlap-left-90 {
    display: flex;
    margin-left: -90px;
}

.vertical-center {
    display: flex;
}

.content-card {
    padding: 30px;
    height: 300px;
    border-radius: 2px;
    box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
    flex-direction: column;
    width: 100%;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: stretch;
}

.content-card ul {
    list-style-type: none
}

.content-card li {
    font-size: 22px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.92;
    letter-spacing: normal;
    color: #000000;
}

.content-red {
    color: #ed3d37;
}

.content-span {
    width: 21px;
    height: 48px;
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #ed3d37;
    position: absolute;
    margin-left: -35px;
}

.content-card-dwarf {
    height: 220px;
}

.content-card-dwarf li {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.2;
    letter-spacing: normal;
    color: #000000;
}

.content-card h2 {
    font-size: 26px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.5px;
    color: #ed3d37;
}

.content-card-limited {
    max-width: 625px;
}

.content {
    padding-top: 25px;
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #000000;
    max-width: 620px;
}

.plussign {
    font-size: 48px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #ed3d37;
    position: absolute;
}

.spaced {
    margin-left: 38px;
}

.diff {
    color: #ed3d37;
}

.padding-top {
    padding-top: 100px;
}

.p-40 {
    padding: 40px;
}

.z1 {
    z-index: 1;
}

.services-text {
    font-size: 24px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #000000;
}

.more-services-text {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: normal;
    color: #000000;
}

.we-text {
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #000000;
}

.logica {
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #000000;
}

.albert {
    margin-top: 54px;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.13;
    letter-spacing: normal;
    color: #ed4334;
}

.footer {
    height: 200px
}

.footer p {
    width: 100%;
    margin-top: 20px;
    opacity: 0.5;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #ffffff;
}

.links {
    align-items: center;
    margin-left: auto !important;
    margin-top: 10px;
}

.footer-logo {
    text-align: left;
}

.active {
    opacity: 1 !important;
    z-index: 3;
}

.icon {
    width: 23px;
    height: 21px;
}

.fade {
    display: none;
}

.caret {
    font-size: 18px;
    margin-right: 7px;
}

/* Style the buttons that are used to open and close the ºrdion panel */
.accordion {
    margin-top: 10px;
    width: 380px;
    background-color: transparent;
    cursor: pointer;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ed3d37;
    user-select: none;
}

/* Style the accordion panel. Note: hidden by default */
.panel {
    padding: 0 18px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in;
}

.bio {
    padding: 0 40px 40px;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #000000;
}

.padding-5 {
    padding: 3rem;
}

@media (max-width: 1440px) {
    .bio {
        padding: 0;
    }
}

@media (min-width: 768px) {
    .hide-md {
        display: none !important;
    }

    .rojo2-right {
        right: -70px;
    }

    .menu-link-text.active::after {
        transform: scaleY(1);
    }

    .menu-link-text:hover::after {
        transform: scaleY(1);
        transform-origin: bottom;
    }
}

@media (max-width: 768px) {
    .padding-5 {
        padding: 1rem;
    }

    .rojo-left {
        width: 69px;
        position: absolute;
        left: 20px;
        margin-top: -36px;
        z-index: 1;
    }

    .accordion {
        width: 100%;
    }

    .section_first {
        text-align: center;
        z-index: 1;
    }

    .section_intro {
        padding-top: 100px;
        padding-bottom: 50px;
    }

    .section_intro h4 {
        margin-top: 100px;
    }

    .section_intro h1 {
        padding-left: 15px;
        padding-right: 15px;
        font-size: 36px;
        line-height: 46px;
    }

    .section_intro .outro {
        margin-top: 20px;
    }

    .more-services-text {
        padding-top: 22px;
    }

    .section_overlay {
        z-index: 1;
        margin-top: 0;
        max-width: 1082px;
        height: auto;
        background: white;
        border-radius: 0;
        box-shadow: none;
        padding-bottom: 20px;
    }

    .section_overlay h1 {
        font-size: 26px;
        line-height: 36px;
    }

    .section_padding {
        text-align: center;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .section_padding h1 {
        font-size: 26px;
        line-height: 36px;
    }

    .mp {
        color: white !important;
    }

    .section_padding h4 {
        font-size: 26px;
    }

    .plussign {
        font-size: 40px;
        margin-left: 4px;
    }

    .we-text {
        font-size: 14px;
    }

    .section_red a, p {
        font-size: 22px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.34px;
        color: #000000;
    }

    .p-5 {
        padding: 2rem !important;
    }

    .content_strategy {
        font-size: 16px;
        padding-bottom: 50px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .rojo {
        width: 109px;
        height: 134px;
        position: absolute;
        right: 20px;
        margin-top: -20px;
        z-index: 1;
    }

    .hide-sm {
        display: none !important;
    }

    .section_gray h1 {
        font-size: 26px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.23;
        letter-spacing: normal;
        text-align: center;
        color: #000000;
    }

    .section_gray p {
        margin-top: 20px;
    }

    .p-owner {
        padding-left: 40px;
        padding-right: 40px;
    }

    .content-card {
        border-radius: 0;
        height: auto;
        box-shadow: none;
        text-align: left;
        padding-left: 0;
        padding-top: 15px;
        padding-right: 0;
    }

    .content-card li {
        font-size: 18px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.52;
        letter-spacing: normal;
        color: #000000;
    }

    .content-span {
        width: 21px;
        height: 48px;
        font-size: 35px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.82;
        letter-spacing: normal;
        color: #ed3d37;
        position: absolute;
        margin-left: -35px;
    }

    .content-card h2{
        font-size: 20px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.82;
        letter-spacing: -0.32px;
        color: #ed3d37;
    }

    .content-card p {
        font-size: 18px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.36;
        letter-spacing: normal;
        color: #000000;
    }

    .content-card-dwarf {
        margin-top: 20px;
        margin-bottom: 20px;
        text-align: center;
        padding-left: 25px;
        padding-right: 25px;
    }

    .content-card-dwarf li {
        text-align: left;
    }

    .rojo-lessmargin {
        margin-bottom: 20px;
        width: 160px;
        height: 195px
    }

    .logica {
        padding-left: 15px;
        padding-right: 15px;
        font-size: 26px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.54;
        letter-spacing: normal;
        text-align: center;
        color: #000000;
    }

    .albert {
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        color: #ed3d37;
    }

    .footer-logo {
        text-align: center;
    }

    .menu {
        margin-left: 0;
        margin-right: 0;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        transition: all 0.7s;
        -webkit-transition: all 0.7s;
        -moz-transition: all 0.7s;
        -o-transition: all 0.7s;
    }

    .menu-link {
        margin-left: auto;
        margin-right: 20px;
    }

    .section_red {
        height: 100vh;
    }

    .menu-link-text {
        position: relative;
        font-size: 18px;
        line-height: 30px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: .2px;
        text-align: right;
        color: #fff;
    }

    .menu-link-text::after {
        position: absolute;
        content: "";
        margin-top: 0;
        width: 100%;
        height: 3px;
        top: 100%;
        left: 0;
        background: #ed3d37;
        transition: transform 0.1s;
        transform: scaleY(0);
        transform-origin: bottom;
    }

    .black-menu {
        background: black;
    }

    .mob-logo {
        height: 32px;
        cursor: pointer;
    }

    .exp-logo {
        height: 70px;
    }

    .hamburger {
        height: 17px;
    }

    .small {
        height: 35px;
        margin-top: 15px;
        margin-bottom: 32px;
    }

    .ext-hamburger {
        display: none;
        z-index: 20;
        top: 92px;
        left: 0;
        right: 0;
        position: fixed;
    }

    .swiper-container {
        height: 490px;
    }
}