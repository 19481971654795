@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap');
@import url("../node_modules/swiper/css/swiper.min.css");

@media (min-width: 1440px) {
  .container{
    padding-left: 70px !important;
    padding-right: 70px !important;
    max-width: 1280px !important;
  }
}

@media (min-width: 1440px) {
  .main-container{
    max-width: 1440px !important;
  }
}

body {
  font-family: 'Montserrat', sans-serif;
  min-width: calc(100vw - 15px);
  min-height: 100vh;
  -webkit-backface-visibility: hidden;
}

/* -- ANIMATIONS --*/
.example-appear {
  opacity: 0.01;
}

.exp-logo {
  cursor: pointer;
}

.example-appear.example-appear-active {
  opacity: 1;
  transition: opacity .2s ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}
/* -- ANIMATIONS --*/

/* -- MENU --*/
.menu-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background: black;
  z-index: 3;
  overflow: auto;
}

.menu-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  margin: auto;
  margin-top: 75px;
  text-align: left;
  padding-left: 15px;
}

.menu-logo {
  margin-left: 20px !important;
}

.menu-title {
  font-family: 'Oswald', sans-serif;
  font-size: 50px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.08;
  letter-spacing: normal;
  color: #c7a56b;
  margin-bottom: 20px;
  padding-left: 20px;
  cursor: pointer;
  transition: all 0.3s;
}

.menu-title a{
  font-family: 'Oswald', sans-serif;
  font-size: 50px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.08;
  letter-spacing: normal;
  color: #c7a56b;
  margin-bottom: 20px;
  cursor: pointer;
  transition: all 0.3s;
}

.menu-title a:hover {
  color: white;
  text-decoration: none;
}

.menu-title:hover {
  color: white;
  text-decoration: none;
}

.menu-subtitle {
  font-family: 'Oswald', sans-serif;
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 2px;
  color: #ffffff;
  margin-top: 20px;
  padding-left: 20px;
}

.menu-subtitle-first {
  margin-top: 100px;
}

.menu-close {
  width: 120px;
  cursor: pointer;
  color: white;
  text-align: right;
}

.menu-x {
  margin-right: 5px;
}

.menu-social-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 35px;
  text-align: right;
}

.menu-social-content{
  padding-right: 70px;
}

.menu-social-content:first-of-type {
  margin-bottom: 45px;
}

.menu-social-text-small {
  font-family: 'Oswald', sans-serif;
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 2px;
  color: #ffffff;
}

.menu-social-text-small a{
  font-family: 'Oswald', sans-serif;
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 2px;
  color: #ffffff;
}

.menu-container-left {
  padding-left: 50px !important;
}

.menu-social-text{
  opacity: 0.8;
  font-family: 'Oswald', sans-serif;
  font-size: 32px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.3s;
}

.menu-social-text:hover {
  color: #EACA94;
  text-decoration: none;
}


.menu-social-text a{
  opacity: 0.8;
  font-family: 'Oswald', sans-serif;
  font-size: 32px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.3s;
}

.menu-social-text a:hover {
  color: #EACA94;
  text-decoration: none;
}

/* -- MENU --*/

.app-header {
  height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.brand {
  color: #c7a56b;
}

.subtitle {
  margin-top: 80px;
  margin-bottom: 42px;
  font-family: 'Oswald', sans-serif;
  font-size: 30px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.4;
  letter-spacing: normal;
  text-align: center;
  color: #c7a56b;
}

.intro {
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.menu-link {
  margin: auto;
  display: flex;
  flex-direction: row;
  z-index: 10;
  cursor: pointer;
  justify-content: center;
}

.menu-link-icons {
  height: 22px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.menu-link-icon {
  margin-top: 1px;
  width: 24px;
  height: 2px;
  background-color: #ffffff;
}

.menu-link-icon:last-of-type {
  width: 12px;
  margin-left: 12px;
  margin-top: 3px;
}

.menu-link-text {
  position: relative;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: right;
  color: #ffffff;
}

.menu-link-text::after {
  position: absolute;
  content: "";
  margin-top: 20px;
  width: 100%;
  height: 3px;
  top: 100%;
  left: 0;
  background: #ed3d37;
  transition: transform 0.1s;
  transform: scaleY(0);
  transform-origin: bottom;
}

.box-container {
  margin-top: 115px;
}

.box {
  width: 31.66%;
  justify-content: center;
  text-align: center;
}

.box:first-of-type {
  margin-left: 2.5%;
}

.box:last-of-type {
  margin-right: 2.5%;
}

.byc-text {
  color: white;
  padding-left: 10px;
  padding-right: 20px;
}

.box-title {
  font-family: 'Oswald', sans-serif;
  font-size: 40px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.8;
  letter-spacing: normal;
  color: #c7a56b;
}

.box-content {
  margin: auto;
  max-width: 340px;
  padding-left: 10px;
  padding-right: 10px;
}

.box-text {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #f1f1f1;
}

.box-link {
  margin-top: 5px;
  color: #c7a56b;
}

.box-link:hover {
  color: #fcc566;
}

.box-logo {
  object-fit: contain;
  margin-top: 30px;
  margin-bottom: 25px;
}

.program-item {
  height: 50px;
  display: flex;
  margin-top: 20px;
  margin-bottom: 70px;
}

.program-item-text {
  font-family: 'Oswald', sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin: auto;
}

.program-item-text-alt {
  font-family: 'Oswald', sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin: auto;
}

.program-item-content {
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: center;
  color: #a7a9ac;
}

.program-item-icon {
  width: 50px;
  height: 50px;
  margin-top: -10px;
  margin-right: 15px;
  float: left;
  display: block;
  object-fit: contain;
}

.nopadding {
  padding: 0 !important;
}

.nopaddingleft {
  padding-right: 0 !important;
}

.nopaddingright {
  padding-left: 0 !important;
}

.celebration {
  min-height: 570px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.izquierda {
  background-image: linear-gradient(to left, #d9bd88, #b18950);
}

.cardtext {
  text-align: left;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex: 1;
}

.cardtext-subtitle {
  margin-top: 45px;
  font-family: 'Oswald', sans-serif;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.73;
  letter-spacing: 0.83px;
  color: #ffffff;
  flex: 0;
}

.cardtext-title {
  font-family: 'Oswald', sans-serif;
  font-size: 50px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: 0.89px;
  color: #ffffff;
}

.cardtext-content {
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #f1f1f1;
}

.cardtext-button {
  width: 208px;
  height: 50px;
  display: flex;
  align-items: center;
  background-color: #000000;
  font-family: 'Oswald', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 5px;
  color: #ffffff;
  transition: all 0.3s;
  cursor: pointer;
}

.cardtext-button:hover {
  color: black;
  background-color: white;
}

.cardtext-button:hover .cardtext-arrow {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

.cardtext-arrow {
  vertical-align: unset;
  margin-left: 8px;
  transition: all 0.3s;
}

.nuestras {
  margin-top: 140px;
  font-family: 'Oswald', sans-serif;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 7.5px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 0;
}

.experiencias {
  margin-top: 0;
  font-family: 'Oswald', sans-serif;
  font-size: 50px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: #c7a56b;
}

.contentbox {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 610px;
  height: 480px;
  box-shadow: 0 40px 50px 0 rgba(0, 0, 0, 0.1);
  background-color: #000000;
  margin-top: 40px;
  padding: 60px;
  z-index: 1;
  position: relative;
}

.contentbox-left {
  margin-left: 230px;
}

.contentbox-right {
  margin-left: -230px;
}

.contentbox-subtitle {
  font-family: 'Oswald', sans-serif;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 7.5px;
  color: #ffffff;
}

.contentbox-title {
  font-family: 'Oswald', sans-serif;
  font-size: 60px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #c7a56b;
}

.contentbox-text {
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #a7a9ac;
}

.contentbox-button {
  width: 208px;
  height: 50px;
  display: flex;
  align-items: center;
  background-color: #000000;
  font-family: 'Oswald', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 3.5px;
  color: #ffffff;
  transition: all 0.3s;
  cursor: pointer;
  border: solid 1px #c7a56b;
}

.contentbox-button:hover {
  color: black;
  background-color: #c7a56b;
}

.contentbox-button:hover .contentbox-arrow {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

.contentbox-arrow {
  margin-right: 20px;
  vertical-align: unset;
  transition: all 0.3s;
}

.social {
  margin-top: 120px;
  height: 236px;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.social-title {
  font-family: 'Oswald', sans-serif;
  font-size: 32px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 8px;
  text-align: center;
  color: #2f3141;
}

.social-item {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 50px;
  color: #c7a56b;
}

.social-item:hover {
  text-decoration: none;
  color: #c7a56b;
}

.margin-row {
  margin-top: 52px;
  margin-bottom: 52px;
}

.center3 {
  text-align: center;
  justify-content: center;
}

.hr {
  background-color: rgba(255, 255, 255, 0.2);
  height: 1px;
  margin-top: 40px;
  margin-left: 40px;
  margin-right: 40px;
}

.vertical-center {
  align-items: center;
}

.bases-text {
  color: white;
  padding: 20px;
  text-align: left;
}

.modal-danger .modal-content {
  border-color: #f86c6b;
}

.modal-danger .modal-header {
  color: #fff;
  background-color: #f86c6b;
}

.modal-success .modal-content {
  border-color: #4dbd74;
}

.modal-success .modal-header {
  color: #fff;
  background-color: #4dbd74;
}

.link {
  text-decoration: none;
  color: white;
}

.link:hover {
  text-decoration: none;
}

a:hover {
  text-decoration: none !important;
}

@media (max-width: 768px) {
  .app-header {
    margin-top: 10px;
    height: auto;
    margin-bottom: 40px;
  }

  .bases-text {
    padding: 0;
  }

  .container-fluid {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .container-fluid .row {
    margin-left: 0;
    margin-right: 0;
  }

  .menu-container-left {
    padding-left: 0 !important;
  }

  .menu-title-first-m {
    margin-top: 50px !important;
  }

  .menu-logo-m {
    margin-top: -5px !important;
  }

  .menu-wrapper {
    margin-top: 27px;
    width: 100%;
    padding-left: 0;
  }

  .nopadding-m {
    padding: 0 !important;
  }

  .menu-social-container {
    text-align: left;
    padding-left: 0;
  }

  .menu-subtitle {
    margin-top: 0;
    padding-left: 10px;
    font-size: 16px;
  }

  .menu-title {
    font-size: 32px;
    padding-left: 10px;
    margin-top: 15px;
  }

  .menu-social-text {
    padding-left: 10px;
    font-size: 24px;
  }

  .menu-social-text a{
    padding-left: 10px;
    font-size: 24px;
  }

  .menu-social-text-small a{
    padding-left: 10px;
  }

  .menu-social-text-small {
    padding-left: 10px;
  }

  .menu-close {
    width: 100%;
    text-align: right;
    margin-top: 0;
    padding-right: 25px;
  }

  .subtitle-alt {
    margin-top: -10px;
  }

  .intro {
    font-size: 20px;
  }

  .program-item {
    margin-bottom: 30px;
  }

  .box {
    width: 100%;
    margin-top: 20px;
    margin-left: 2.5%;
  }

  .box-container {
    margin-top: 20px;
  }

  .cardtext {
    position: relative;
  }

  .izquierda::before {
    content: "";
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    filter: brightness(0.8);
  }

  .cardtext-button {
    background-color: #c7a56b;
  }

  .izquierda {
    position: relative;
  }

  .derecha {
    display: none;
  }

  .row-inverted-m {
    flex-direction: column-reverse;
  }

  .contentbox {
    margin: auto !important;
    width: auto !important;
    height: auto !important;
  }

  .cardtext-title {
    font-size: 42px;
  }

  .cardtext-subtitle {
    line-height: 1;
  }

  .social-and {
    display: none;
  }

  .program-item-text {
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
  }

  .nuestras {
    margin-top: 45px;
  }

  .experiencias {
    margin-bottom: 30px;
  }

  .margin-row {
    margin-top: auto;
    margin-bottom: auto;
  }

  .quiz {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 100%;
  }

  .quiz-container {
    max-height: 280px;
    box-shadow: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    background-image: none;
  }

  .quiz-introtext {
    font-size: 20px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }

  .quiz-button-left {
    margin-right: auto;
  }

  .quiz-button-right {
    margin-left: auto;
  }

  .quiz-topbar {
    padding: 20px;
  }

  .quiz-wrapper {
    padding-left: 0;
    width: 100%;
  }

  .quiz-row {
    display: none !important;
  }

  .quiz-row-mobile {
    display: block !important;
    flex-direction: column;
    align-items: center;
  }

  .quiz-button {
    background: transparent;
    height: auto;
    width: auto;
  }

  .quiz-button:hover {
    color: #c7a56b;
  }

  .quiz-end-text {
    font-size: 24px;
  }

  .quiz-end-buttons-container {
    margin-top: 75px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 120px;
  }

  .program-item-alt {
    margin-bottom: 65px;
  }

  .menu-social-content{
    margin-top: 20px;
  }

  .menu-social-content:first-of-type {
    margin-bottom: 0;
  }

  .menu-subtitle {
    display: none;
  }

  .menu-social-text-small {
    display: none;
  }

  .menu-social-text-small a{
    display: none;
  }

  .menu-title-first {
    margin-top: 50px
  }
}

@media (max-width: 445px) {
  .quiz-introtext {
    display: none;
  }

  .program-item-text {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
  }

  .menu-social-text {
    margin-left: 0;
    padding-left: 0;
  }

  .menu-social-text a{
    margin-left: 0;
    padding-left: 0;
  }
}

@media (max-width: 320px) {
  .program-item-text {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
}
